@import 'lib/font-awesome';

@font-face {
    font-family: 'Telefonica';
    src: url('fonts/Telefonica-Regular.eot');
    src: url('fonts/Telefonica-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/Telefonica-Regular.woff2') format('woff2'),
        url('fonts/Telefonica-Regular.woff') format('woff'),
        url('fonts/Telefonica-Regular.ttf') format('truetype'),
        url('fonts/Telefonica-Regular.svg#Telefonica-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Telefonica-Bold';
    src: url('fonts/Telefonica-Bold.eot');
    src: url('fonts/Telefonica-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Telefonica-Bold.woff2') format('woff2'),
        url('fonts/Telefonica-Bold.woff') format('woff'),
        url('fonts/Telefonica-Bold.ttf') format('truetype'),
        url('fonts/Telefonica-Bold.svg#Telefonica-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Telefonica-Light';
    src: url('fonts/Telefonica-Light.eot');
    src: url('fonts/Telefonica-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/Telefonica-Light.woff2') format('woff2'),
        url('fonts/Telefonica-Light.woff') format('woff'),
        url('fonts/Telefonica-Light.ttf') format('truetype'),
        url('fonts/Telefonica-Light.svg#Telefonica-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Telefonica-Hand';
    src: url('fonts/Telefonica-Hand.eot');
    src: url('fonts/Telefonica-Hand.eot?#iefix') format('embedded-opentype'),
        url('fonts/Telefonica-Hand.woff2') format('woff2'),
        url('fonts/Telefonica-Hand.woff') format('woff'),
        url('fonts/Telefonica-Hand.ttf') format('truetype'),
        url('fonts/Telefonica-Hand.svg#Telefonica-Hand') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Telefonica-Icons';
    src: url('fonts/telefonica-icons.eot');
    src: url('fonts/telefonica-icons.eot?#iefix') format('embedded-opentype'),
        url('fonts/telefonica-icons.woff2') format('woff2'),
        url('fonts/telefonica-icons.woff') format('woff'),
        url('fonts/telefonica-icons.ttf') format('truetype'),
        url('fonts/telefonica-icons.svg#telefonica-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?jsa9iz');
  src:  url('fonts/icomoon.eot?jsa9iz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?jsa9iz') format('truetype'),
    url('fonts/icomoon.woff?jsa9iz') format('woff'),
    url('fonts/icomoon.svg?jsa9iz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-like:before {
  content: "\e900";
}
.ico-newsletter:before {
  content: "\e901";
}
.ico-open-book:before {
  content: "\e902";
}
.ico-plant:before {
  content: "\e903";
}
.ico-plant-heart:before {
  content: "\e904";
}
.ico-profile-info:before {
  content: "\e905";
}
.ico-star:before {
  content: "\e906";
}
.ico-sun:before {
  content: "\e907";
}
.ico-user:before {
  content: "\e908";
}
.ico-user-settings:before {
  content: "\e909";
}
.ico-arrow-down:before {
  content: "\e90a";
}
.ico-building:before {
  content: "\e90b";
}
.ico-building-public:before {
  content: "\e90c";
}
.ico-calendar:before {
  content: "\e90d";
}
.ico-cart:before {
  content: "\e90e";
}
.ico-chat:before {
  content: "\e90f";
}
.ico-clock:before {
  content: "\e910";
}
.ico-diamond:before {
  content: "\e911";
}
.ico-dislike:before {
  content: "\e912";
}
.ico-document:before {
  content: "\e913";
}
.ico-edit:before {
  content: "\e914";
}
.ico-envelope:before {
  content: "\e915";
}
.ico-hand:before {
  content: "\e916";
}
.ico-hand-heart:before {
  content: "\e917";
}
.ico-hand-money:before {
  content: "\e918";
}
.ico-home:before {
  content: "\e919";
}
.ico-info:before {
  content: "\e91a";
}

