.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba($color: $color-black, $alpha: .3);
    z-index: 9999;
    &:after{
        content:"";
        display: block;
        border: 10px solid $color-white;
        border-top: 10px solid $color-blue;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 1.3s linear infinite;
        position: fixed;
        top: 50%;
        right: 50%;
        transform: translate(50%, 50%);
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}