$color-black: #202020;
$color-purple: #980098;
$color-blue: #003245;
$color-blue--medium: #2593B5;
$color-blue--light: #00EDFF;
$color-white: #FFFFFF;
$color-grey: #f2f3f1;
$color-grey--light: #D3D4D3;
$color-grey--lighter: #F7F5F5;
$color-grey--light-1: #EEEAEA;
$color-grey-light-medium: #DDDCDC; 
$color-grey--medium: #9F9F9F;
$color-grey--dark-medium: #50535A;
$color-grey-dark-2: #86888C;
$color-grey--dark: #86888c;
$color-light-brown: #E4DAD1;
$color-yellow: #FFFF00;
$color-yellow--light: #FEFDAF;
$color-orange: #FF7B00;
$color-orange--light: #FFD8BA;
$color-green: #00FF99;
$color-green--light: #87FDDE;
$color-purple: #980098;
$color-purple--light: #F6BAE3;
$color-red: #d23c31;

$cat-deportes:#00d975;
$cat-viajes:#f27900;
$cat-moda:#ffff01;
$cat-hogar:#00ff99;
$cat-motor:#87fdde;
$cat-restaurante:#a69a2c;
$cat-alimentacion:#58176e;
$cat-salud:#980098;


$box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);

$font-telefonica: 'Telefonica', Arial, Helvetica, sans-serif;
$font-telefonica--bold: 'Telefonica-Bold', Arial, Helvetica, sans-serif;
$font-telefonica--light: 'Telefonica-Light', Arial, Helvetica, sans-serif;
$font-telefonica--hand: 'Telefonica-Hand', Arial, Helvetica, sans-serif;
$font-telefonica--icons: 'Telefonica-Icons', Arial, Helvetica, sans-serif;

$menu_button-size : 1.5rem;
$menu_button-transition: .3s;