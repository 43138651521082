footer{
    z-index: 2;
    background-color: $color-blue;
    color: $color-white;
    padding: 0;
    margin-top: 40px;
    .footer_menu{
        padding: 20px 0;
        .ico{
            color: $color-blue--light; 
            padding: 0;
            margin-top: 15px;
        }
        .footer_title{
            color: $color-blue--light;
            @include font-size(18);
            text-transform: uppercase;
        }
        ul{
            padding: 0;
            li{
                @include font-size(18);
                padding: 5px 0;
                a{
                    display: block;
                    position: relative;
                    &:hover,
                    &:focus,
                    &:active{
                        color: $color-blue--light;
                    }
                    &:after{
                        content: "\f105";
                        font-family: 'FontAwesome';
                        @include font-size(18);
                        color: $color-blue--light;
                        position: absolute;
                        top: 0;
                        right: 5px;
                    }
                    @media (max-width: $screen-xs-max){
                        padding-right: 10px;
                    }
                }
                @media (min-width: $screen-sm-min){
                    @include font-size(16);
                }
            }
        }
    }

    .footer_legal{
        background-color: rgba($color: $color-blue--medium, $alpha: .1);
        padding: 5px 0;
        .footer_logo{
            max-width: 120px;
            margin: 0 auto;
            padding: 10px 0;
            @media (min-width: $screen-sm-min){
                max-width: 86px;
            }
        }
        .footer_link{
            a{
                display: block;
                text-align: center;
                padding: 5px 0;
                @include font-size(16);
                @media (min-width: $screen-sm-min){
                    @include font-size(14);
                    padding: 0 0 3px 0;
                }
            }
        }
        
        @media (min-width: $screen-sm-min){
            .footer_logo{
                margin: 0;
            }
            .footer_link{
                float: right;
                margin-top: 10px;
                a{
                    color: $color-grey;
                    display: inline-block;
                    &:after{
                        content: "|";
                        display: inline-block;
                        padding: 0 5px;
                        height: 100%;
                    }
                    &:last-of-type:after{
                        content: "";
                    }
                }
            }
        }
    }
}