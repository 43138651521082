.module_select{
    label {
        position:relative;
        width: 100%;
    }
    label:after {
        content:"\f107";   
        font-family: "FontAwesome";
        @include font-size(30);
        color: $color-blue--medium;
        right:23px;
        top: 50%;
        transform: translate(-30%, -50%);
        padding:0 0 2px;
        position:absolute;
        pointer-events:none;
        @media (min-width: $screen-sm-min){
            right:5%;
            top: 50%;
            transform: translate(-30%, -50%);
        }
    }
    select{
        font-family: $font-telefonica;
        height: 78px;
        border: 1px solid $color-blue;
        padding-right: 100px;
        border-radius: 0;
        box-shadow: none;
        appearance: none;
        background-image: linear-gradient(to right,#003245,#003245);
        background-position: calc(100% - 3.6em) 0;
        background-size: 1.5px 100%;
        background-repeat: no-repeat;
        &:focus,
        &:active{
            outline: 0;
            border: 1px solid $color-blue; 
            box-shadow: none;
        }
        option{
            font-family: $font-telefonica;
        }
        @media (min-width: $screen-sm-min){
            height: 50px;
            padding-right: 65px;
        }
        @-moz-document url-prefix(){
            padding-right: 80px;
            background-position: calc(100% - 4.8em) 0;
            background-size: 1px 100%;
            @include font-size(18);
        }
    }

    @media (min-width: $screen-sm-min){
        display: none;
    }
}