.header{
    background-color: $color-blue;
    color: $color-white;
    position: relative;
    padding: 25px 0;
    border-bottom: 1px solid $color-blue--light;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9997;
    @media (min-width: $screen-sm-min){
        padding: 15px 0;
    }
    .page_title{
        font-family: $font-telefonica--light;
        @include font-size(36);
        display: none;
        vertical-align: middle;
        margin:0;
        padding-left: 30px;
        @media (min-width: $screen-sm-min){
            display: inline-block;
        }
    }
    .menu_button{
        display: inline-block;
        transition: .3s;
        cursor: pointer;
        user-select: none;
        &:active{
            transition: 0;
            background: rgba(0,0,0,.1);
        }
        .lines{
            display: block; 
            @include line;
            position: relative; 
            &:before, &:after {
             
             @include line;
                position: absolute;
                left:0;
                content: '';
                transform-origin: $menu_button-size/14 center;
            }
            &:before { top: .7rem; }
            &:after { top: -.7rem; }
        }
        &.menu_button--close.x .lines{
            background: transparent;
            &:before, &:after{
              transform-origin: 50% 50%;
              top:0;
              width: 2rem;
            }
            &:before{
              transform: rotate3d(0,0,1,45deg); 
            }
            &:after{
              transform: rotate3d(0,0,1,-45deg); 
            }
        }
        @media (min-width: $screen-sm-min){
            border-radius: $menu_button-size/7;
            .lines{
                &:before { top: $menu_button-size/3; }
                &:after { top: -$menu_button-size/3; }
            }
            &.menu_button--close.x .lines{
                width: $menu_button-size;
            }
        }
    }
    .menu_content{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y:auto;
        height: 100%;
        border: none;
        border-top: 1px solid $color-blue--light;
        background-color: $color-white;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-110%, 0, 0);
        z-index: 9996;
        padding-bottom: 80px;
        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: $color-white; 
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background-color: $color-blue; 
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $color-blue--light; 
        }

        &.slideInLeft {
            -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
            visibility: visible;
        }

        &.slideOutLeft {
            -webkit-animation-name: slideOutLeft;
            animation-name: slideOutLeft;
        }

        & > div{
            margin-bottom: 30px;
        }
        
        .menu_content-panel{
            border: none;
            border-bottom: 2px solid $color-grey;
            border-radius: 0;
            box-shadow: none;
            &:last-of-type{
                border-bottom: 0;
            }
            div{
                background-color: $color-white;
                border: none;
                border-top:none;
                border-color: $color-white;
                border-width: 0;
                border-radius: 0;
                box-shadow: none;
            }
            .panel-heading{
                padding: 16px;
                @media (min-width: $screen-sm-min){
                    padding: 4px 15px;
                }
            }
            .ico{
                color: $color-blue--medium;
                @include font-size(20);
                margin-right: 10px;
            }
            .menu_item{
                color: $color-blue;
                @include font-size(16);
                text-transform: uppercase;
                flex: 6;
            }

            .active{
                border-bottom: .01rem solid $color-blue--medium;
                background-color: rgba($color: $color-blue--medium, $alpha: .3);
                a{                
                    i:before{
                        content: "\f106";
                        float: right;
                        transition: all 0.5s;
                    }

                    &:hover,
                    &:active,
                    &:focus{
                        background-color: transparent;
                    }
                }
            }

            a{
                display: flex;
                align-items: flex-start;
                position: relative;
                width: 100%;
                padding-top: 0;
    
                &:hover,
                &:active,
                &:focus{
                    text-decoration: none;
                    background-color: $color-white;
                }
    
                i{
                    float: right;
                    color: $color-blue;
                    @include font-size(30);
                }
                @media (min-width: $screen-sm-min){
                    display: block;
                }
            }
        }

        .submenu{
            & > div{
                background-color: $color-white;
                border: none;
                border-top:none;
                border-color: $color-white;
                border-width: 0;
                border-radius: 0;
                padding-top: 0;
                padding-bottom: 0;
            }

            li{
                .submenu_item{
                    padding: 0 0 0 30px;
                    a{
                        &:after{
                            content: "";
                            display: block;
                            border-bottom: 1px solid $color-grey;
                            margin-right: -15px;
                        }
                        .ico{
                            color: $color-black;
                        }
                        .submenu_item-title{
                            color: $color-black;
                            padding: 10px 0;
                            display: block;
                            white-space: normal;
                            word-break: break-all;
                            display: inline-block;
                            @include font-size(15);
                        }

                        &:hover{
                            .submenu_item-title,
                            .ico{
                                color: $color-blue--medium;
                            }
                        }
                    }
                }

                &:last-of-type{
                    .submenu_item{
                        a:after{
                            display: none;
                        }
                    }
                }
            }

        }

        @media (min-width: $screen-sm-min){
            width: 300px;
        }
    }

    .header_logo{
        img{
            margin-top: 0;
            @media (min-width: $screen-sm-min){
                max-width: 104px;
                margin-top: 10px;
            }
            @-moz-document url-prefix(){
                margin-top: 10px;
            }
        }
    }

    .header_user{
        float: right;
        .ico-user{
            color: $color-blue--light;
            @include font-size(20);
            border: 2px solid #00EDFF;
            border-radius: 50%;
            @media (min-width: $screen-sm-min){
                display: none;
            }
        }
        p{
            margin: 0;
            @include font-size(22);
            &.header_user--conection{
                @include font-size(14);
                color: $color-blue--medium;
                .ico{
                    padding-left: 0;
                }
                .conect-info{
                    @include font-size(15);
                }
            }
        }
    }
}