.carouselImg-xs{
    .owl-carousel{
        margin: 0 auto;
        .owl-item{
            padding-bottom: 5px;
        }
        .owl-dot{
            span{
                background-color: $color-black;
            }
            &.active{
                span{
                    background-color: $color-blue--light;
                }
            }
        }
    }
}