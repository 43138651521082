.error-message{
    display: none;
    border: 1px solid $color-red;
    padding: 20px;
    .error-message--title{
        @include font-size(30);
        font-family: $font-telefonica--bold;
        color: $color-red;
        &:before{
            content:"!";
            display: inline-block;
            border: 1px solid $color-red;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            text-align: center;
            margin-right: 10px;
            @media (min-width: $screen-sm-min){
                width: 40px;
                height: 40px;
            }
        }
    }
}

.error{
    .module_download-btn{
        .download-btn{
            display: none;
        }
    }
    .error-message{
        display: block;
    }
}