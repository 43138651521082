html{
    font-size: 16px;
    /*height: 100%;*/
}

a,
a:hover,
a:focus,
a:active{
    color: inherit;
    text-decoration: none;
}

ul, ol{
    list-style: none;
}