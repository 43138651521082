.mainBanner{
    padding: 50px 0 0;
    margin-bottom: 10px;
    .mainBanner_title{
        @include font-size(52);
        font-family: $font-telefonica--light;
        color: $color-blue;
        max-width: 270px;
        margin-top: 0;
        @media (min-width: $screen-sm-min){
            max-width: 350px;
            margin-top: 10px;
        }
        @media (max-width: $screen-xs-max){
            @include font-size(32);
            max-width: 100%;
            margin-top: 50px;
        }
    }
    .mainBanner_text{
        @include font-size(16);
        color: $color-black;
        max-width: 100%;
        @media (min-width: $screen-sm-min){
            max-width: 350px;
        }
    }
    .mainBanner_text--bold{
        font-family: $font-telefonica--bold;
        @include font-size(16);
        color: $color-blue;
        margin-top: 20px;
    }
    .mainBanner_imgBox{
        border-top: 3px solid $color-blue;
        box-shadow: 0px 0px 10px 0px rgba(88,88,88,0.2);
        position: relative;
        overflow: hidden;
        img{
            transition: transform .5s ease,-webkit-transform .5s ease;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
        .imgBox_text{
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: $color-white;
            font-family: $font-telefonica--bold;
            @include font-size(18);
            padding: 5px 0;
            text-align: center;
        }
    }
    .mainBanner-content{
        margin-top: 5%;
    }
    @media (min-width: $screen-sm-min){
        background-image: url('../images/mainBanner.png');
        background-position: right center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 30px 0;
        padding: 50px 0 30px;
        margin-bottom: 40px;
        min-height: 289px;
    }
}