@mixin line {
  display: inline-block;
  width: 2.5rem;
  height: .2rem;
  background: $color-blue--light;
  border-radius: .1rem; 
  transition: $menu_button-transition;
  @media (min-width: $screen-sm-min){
    width: $menu_button-size;
    height: $menu_button-size/12;
    border-radius: $menu_button-size/14; 
  }
}

@mixin font-size($sizeValue: 16) {
  font-size: $sizeValue * 1px;
  font-size: $sizeValue / 20 * 1rem;
  @media (min-width: 1440px){
    font-size: $sizeValue * 1px;
    font-size: $sizeValue / 16 * 1rem;
  }
  @media (max-width: $screen-xs-max){
    font-size: $sizeValue * 1px;
    font-size: $sizeValue / 12 * 1rem;
  }
  @-moz-document url-prefix(){
    font-size: $sizeValue * 1px;
    font-size: $sizeValue / 18 * 1rem;
    @media (min-width: 1440px){
      font-size: $sizeValue * 1px;
      font-size: $sizeValue / 16 * 1rem;
    }
    @media (max-width: $screen-xs-max){
      font-size: $sizeValue * 1px;
      font-size: $sizeValue / 18 * 1rem;
    }
  }
}