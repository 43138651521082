// SPACING
@media (min-width: $screen-md-min){
    .p-md-0{
        padding: 0;
    }
    .py-md-0{
        padding-top: 0;
        padding-bottom: 0;
    }
    .px-md-0{
        padding-right: 0;
        padding-left: 0;
    }
    .pr-md-0{
        padding-right: 0;
    }
    .pl-md-0{
        padding-left: 0;
    }
    .mx-md-1{
        margin-left: 15px;
        margin-right: 15px;
    }
}
@media (min-width: $screen-sm-min){
    .p-sm-0{
        padding: 0;
    }
    .py-sm-0{
        padding-top: 0;
        padding-bottom: 0;
    }
    .px-sm-0{
        padding-right: 0;
        padding-left: 0;
    }
    .pr-sm-0{
        padding-right: 0;
    }
    .pl-sm-0{
        padding-left: 0;
    }
    .mx-sm-1{
        margin-left: 15px;
        margin-right: 15px;
    }
}
@media (max-width: $screen-xs-max){
    .p-0{
        padding: 0;
    }
    .py-0{
        padding-top: 0;
        padding-bottom: 0;
    }
    .px-0{
        padding-right: 0;
        padding-left: 0;
    }
    .pr-0{
        padding-right: 0;
    }
    .pl-0{
        padding-left: 0;
    }
    .m-0{
        margin: 0;
    }
    .my-0{
        margin-top: 0;
        margin-bottom: 0;
    }
    .mx-0{
        margin-right: 0;
        margin-left: 0;
    }
    .mr-0{
        margin-right: 0;
    }
    .ml-0{
        margin-left: 0;
    }
    .mx-1{
        margin-left: 15px;
        margin-right: 15px;
    }
}

// ELEMENTS
.border-bottom{
    &:after{
        content: "";
        display: block;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: inherit;
        width: 15px;
        margin-top: 10px;
    }
}

.ico{
    display: inline-block;
    padding: 5px;
    @include font-size(16);
}

.purple-underscore{
    &:after{
        content: "";
        display: inline-block;
        width: 10px;
        border-bottom: 2px solid $color-purple;
        margin-left: 3px;
        @media(min-width: $screen-sm-min){
            width: 6px;
            font-family: $font-telefonica--bold;
        }
    }
}

.blue-underscore{
    &:after{
        content: "";
        display: inline-block;
        width: 10px;
        border-bottom: 2px solid $color-blue;
        margin-left: 3px;
        @media(min-width: $screen-sm-min){
            width: 6px;
            font-family: $font-telefonica--bold;
        }
    }
}

.blue--medium-underscore{
    &:after{
        content: "";
        display: inline-block;
        width: 10px;
        border-bottom: 2px solid $color-blue--medium;
        margin-left: 3px;
        @media(min-width: $screen-sm-min){
            width: 6px;
            font-family: $font-telefonica--bold;
        }
    }
}

.blue--light-underscore{
    &:after{
        content: "";
        display: inline-block;
        width: 10px;
        border-bottom: 2px solid $color-blue--light;
        margin-left: 3px;
        @media(min-width: $screen-sm-min){
            width: 6px;
            font-family: $font-telefonica--bold;
        }
    }
}

// BACKGROUNDS

.bg{
    padding: 20px 0;
    &.bg--full{
        padding:20px 0;
        @media (min-width: $screen-md-min){
            padding: 30px 20px 10px 10px;
        }
    }
    &.bg--px-0{
        padding-left: 0;
        padding-right: 0;
    }
}

.bg_blue{
    background-color: $color-blue;
    &.module_title,
    .module_title{
        .ico{
            color: $color-blue--light;
        }
        .module_title--title{
            color: $color-white;
            .module_title--tooltip{
                color: $color-blue--medium;
                span{
                    background-color: $color-blue--medium;
                    color: $color-white;
                    border: 1px solid $color-white;
                    &:after{
                        border-color: $color-blue--medium transparent transparent transparent;
                    }
                }
            }
        }
        .module_title--description{
            color: $color-blue--light;
        }
    }
}

.bg_blue--medium{
    background-color: $color-blue--medium;
    &.module_title,
    .module_title{
        .ico{
            color: $color-blue--light;
        }
        .module_title--title{
            color: $color-blue;
            .module_title--tooltip{
                color: $color-blue--light;
                span{
                    background-color: $color-blue--medium;
                    color: $color-white;
                    border: 1px solid $color-white;
                    &:after{
                        border-color: $color-blue--medium transparent transparent transparent;
                    }
                }
            }
        }
        .module_title--description{
            color: $color-white;
        }
    }
}

.bg_blue--grey{
    background: linear-gradient(to bottom, $color-blue 55%, $color-grey 55%);
    .module_title{
        .ico{
            color: $color-blue--light;
        }
        .module_title--title{
            color: $color-white;
            .module_title--tooltip{
                color: $color-blue--light;
                span{
                    background-color: $color-blue--medium;
                    color: $color-white;
                    border: 1px solid $color-white;
                    &:after{
                        border-color: $color-blue--medium transparent transparent transparent;
                    }
                }
            }
        }
        .module_title--description{
            color: $color-blue--light;
        }
    }
    @media (min-width: $screen-sm-min){
        background: linear-gradient(to right, $color-blue 35%, $color-grey 35%);
    }
}

.bg_grey{
    background-color: $color-grey;
    .module_title{
        .ico{
            color: $color-blue--medium;
        }
        .module_title--title{
            color: $color-blue;
            .module_title--tooltip{
                color: $color-blue--medium;
                span{
                    background-color: $color-blue;
                    color: $color-white;
                    border: 1px solid $color-white;
                    &:after{
                        border-color: $color-blue transparent transparent transparent;
                    }
                }
            }
        }
        .module_title--description{
            color: $color-blue--medium;
        }
    }
}

.bg_white{
    background-color: $color-white;
    &.module_title,
    .module_title{
        .ico{
            color: $color-blue;
        }
        .module_title--title{
            color: $color-blue;
            .module_title--tooltip{
                color: $color-blue;
                span{
                    background-color: $color-blue;
                    color: $color-white;
                    border: 1px solid $color-white;
                    &:after{
                        border-color: $color-blue transparent transparent transparent;
                    }
                }
            }
        }
        .module_title--description{
            color: $color-blue--medium;
        }
    }
}

.module_products .cat-deportes{border-top:5px solid $cat-deportes;}
.module_products .cat-viajes{border-top:5px solid $cat-viajes;}
.module_products .cat-moda{border-top:5px solid $cat-moda;}
.module_products .cat-hogar{border-top:5px solid $cat-hogar;}
.module_products .cat-motor{border-top:5px solid $cat-motor;}
.module_products .cat-restaurante{border-top:5px solid $cat-restaurante;}
.module_products .cat-alimentacion{border-top:5px solid $cat-alimentacion;}
.module_products .cat-salud{border-top:5px solid $cat-salud;}

@media (min-width: $screen-sm-min){
    .cat-deportes.active, .cat-deportes.hover{border-top:3px solid $cat-deportes;}
    .cat-viajes.active, .cat-viajes.hover{border-top:3px solid $cat-viajes;}
    .cat-moda.active, .cat-moda.hover{border-top:3px solid $cat-moda;}
    .cat-hogar.active, .cat-hogar.hover{border-top:3px solid $cat-hogar;}
    .cat-motor.active, .cat-motor.hover{border-top:3px solid $cat-motor;}
    .cat-restaurante.active, .cat-restaurante.hover{border-top:3px solid $cat-restaurante;}
    .cat-alimentacion.active, .cat-alimentacion.hover{border-top:3px solid $cat-alimentacion;}
    .cat-salud.active, .cat-salud.hover{border-top:3px solid $cat-salud;}

    .module_products .cat-deportes, .module_products .cat-viajes, .module_products .cat-moda, .module_products .cat-hogar, .module_products .cat-motor, .module_products .cat-restaurante, .module_products .cat-alimentacion, .module_products .cat-salud{border-width: 3px;}
}

.button-blue{
    width: 100%;
    display: block;
    border: 1px solid $color-blue;
    background-color: $color-blue;
    color: $color-white;
    padding: 5px 30px;
    margin-top: 20px;
    
    cursor: pointer;
    &.visible{
        display: block;
    }
    :-moz-read-only{
        background-color: $color-blue;
        color: $color-white;
    } 
    &.register-button {
        display: block;
    }
}
.button-white{
    width: 100%;
    display: block;
    border: 1px solid $color-blue;
    background-color: $color-white;
    color: $color-blue;
    padding: 5px 22px;
    margin-top: 10px;
    
    cursor: pointer;
    &.visible{
        display: block;
    }
    :-moz-read-only{
        background-color: $color-white;
        color: $color-blue;
    } 
}
section.module_two-col {
	margin-bottom: 15px;
}