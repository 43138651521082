.module_title{
    padding: 30px 20px 20px;
    .ico{
        @include font-size(36);
    }
    .module_title--title{
        font-family: $font-telefonica--bold;
        @include font-size(26);
        line-height: 1.2;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        p{
            align-self: flex-start;
            flex: 1;
            flex-basis: 90%;
            @media (min-width: $screen-md-min){
                width: 250px;
                flex-basis: auto;
                flex: unset;
            }
        }
        .module_title--tooltip{
            @include font-size(18);
            position: relative;
            margin-left: 0px;
            outline: none;
            align-self: stretch;
                span{
                    @include font-size(14);
                    visibility: hidden;
                    font-family: $font-telefonica;
                    background-color: $color-black;
                    color: $color-white;
                    border: 1px solid $color-black;
                    width: 120px;
                    text-align: center;
                    padding: 5px;
    
                    /* Position the tooltip text */
                    position: absolute;
                    z-index: 1;
                    bottom: 115%;
                    left: 50%;
                    margin-left: -60px;
    
                    /* Fade in tooltip */
                    opacity: 0;
                    transition: opacity 0.3s;
    
                    &:after,
                    &:before{
                        content: "";
                        position: absolute;
                        top: 99%;
                        left: 50%;
                        margin-left: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: $color-black transparent transparent transparent;
                    }
                    &:before{
                        border-color: $color-white transparent transparent transparent;
                        border-width: 7px;
                        margin-left: -7px;
                    }
                }
                &:hover{
                    @media (min-width: $screen-sm-min){
                        span{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            @media(max-width: 1024px){
                span{
                    display: none;
                }
            }
        }
        @media (min-width: $screen-sm-min){
            @include font-size(36);
        }
    }
    .module_title--description{
        @include font-size(18);
        width: auto;
        @media (min-width: $screen-sm-min){
            width: 150px;
            @include font-size(16);
        }
    }

    &.px-0{
        padding-left: 0;
        padding-right: 0;
    }
    
    &.product{
        img{
            max-width: 50px;
        }
        .module_title--description{
            @include font-size(20);
        }
    }
}


.bg--px-0{
    &.module_title,
    .module_title{
        padding: 30px 5px 20px;
    }
}