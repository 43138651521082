.menu_destacados{
    background-color: rgba($color: $color-blue--medium, $alpha: .3);
    position: absolute;
    right: 0;
    left: 0;
    z-index: 9996;

    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        .owl-stage{
            display: flex;
            flex-direction: row;
            align-items: stretch;
            .owl-item{
                border-right: .5px solid $color-white;
                flex: 1;
                align-self: stretch;
                float: none;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0;
                &:last-of-type{
                    border-right: 0;
                }
                &:hover,
                &:focus,
                &:active{
                    background-color: rgba($color: $color-white, $alpha: .5);
                }
                li{
                    &:hover,
                    &:focus,
                    &:active{
                        background-color: transparent;
                    }
                }
            }
        }
        li{
            a{
                font-family: $font-telefonica--bold;
                text-transform: uppercase;
                color: $color-black;
                @include font-size(16);
                display: block;
            }
        }
    }

    @media (min-width: $screen-sm-min){
        ul{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            li{
                flex: 1;
                align-self: stretch;
                padding: 5px 0;
                border-right: .5px solid $color-white;
                &:last-of-type{
                    border-right: 0;
                }
                &:hover,
                &:focus,
                &:active{
                    background-color: rgba($color: $color-white, $alpha: .5);
                }
            }
        }
    }
}