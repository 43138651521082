.modal{
    z-index: 9999;
    color: $color-black;
    .modal-content{
        border-radius: 0;
        .modal-header{
            border-bottom: 0;
            padding: 0;
            .btn-close{
                background-color: $color-blue;
                padding: 10px;
                opacity: 1;
                margin-top: 0;
                border: 0;
                float: right;
                .close{
                    position: relative;
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    overflow: hidden;
                    opacity: 1;
                    &:hover {
                      &::before, &::after {
                        background: $color-blue--light;
                      }
                    }
                    &::before, &::after {
                      content: '';
                      position: absolute;
                      height: 1px;
                      width: 100%;
                      top: 50%;
                      left: 0;
                      margin-top: -1px;
                      background: $color-blue--light;
                    }
                    &::before {
                      transform: rotate(45deg);
                    }
                    &::after {
                      transform: rotate(-45deg);
                    }
                    @media (min-width: $screen-md-min) {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
        .modal-body{
            padding: 30px;
            .modal-body_title{
                color: $color-blue--medium;
                font-family: $font-telefonica--light;
                @include font-size(24);
            }
            .modal-body_content{
                @include font-size(16);
                line-height: 2;
                p{
                    margin-top: 10px;
                }
                strong, b{
                    font-family: $font-telefonica--bold;
                }
                .content--header{
                    margin-bottom: 30px;
                    p{
                        margin: 0;
                    }
                } 
                .public-input{
                    width: 100%;
                    background-color: transparent;
                    border-width: 0 0 1px;
                    height: auto;
                    padding-right: ($input-height-base * 1.25);
                }
                ::placeholder{
                    color: $color-grey--light;
                }
            }
            @media (min-width: $screen-sm-min){
                padding: 40px 60px;
            }
        }
        .modal-footer{
            border-top: 0;
            .btn-close{
                border: 0;
                border-radius: 0;
                background-color: $color-blue;
                color: $color-white;
                padding: 5px 40px;
            }
        }
    }
}
.modal-backdrop{
    z-index: 9998;
}

.modal_form{
    .mensaje{
        text-align: center;
        .ico{
            border: 2px dashed $color-blue--medium;
            background-color: rgba($color: $color-blue--light, $alpha: .1);
            padding: 40px;
            @include font-size(60);
            color: $color-blue--medium;
        }
        &.OK{
            display: none;
        }
        &.KO{
            display: none;
            .ico{
                border: 2px dashed $color-red;
                background-color: rgba($color: $color-red, $alpha: .1);
                color: $color-red;
            }
        }
    }
    .modal-content .modal-body .modal-body_content{
        p{
            text-align: center;
        }
    }

    &.error{
        .mensaje{
            &.KO{
                display: block;
            }
        }
    }

    &.success{
        .mensaje{
            &.OK{
                display: block;
            }
        }
    }
}