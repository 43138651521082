.news{
    .news_content{
        margin: 5px 5px 30px 0;
        box-shadow: 0 0 10px 0 rgba(88,88,88,.2);
        position: relative;
        a{
            .news_img{
                overflow: hidden;
                img{
                    transition: transform .5s ease,-webkit-transform .5s ease;
                }
            }
            &:hover{
                .news_img{                    
                    img{
                        transform: scale(1.1);
                    }
                }
            }
        }
        .news_content--description{
            background-color: $color-white;
            padding: 10px;
            text-align: center;
            color: $color-black;
            @include font-size(16);
        }
        .news_content--link{
            a{
                display: flex;
                align-items: stretch;
                display: none;
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translate(-50%);
                span{
                    background-color: $color-blue;
                    color: $color-white;
                    @include font-size(16);
                    padding: 6px 10px;
                    align-self: center;
                }
                i{
                    background-color: $color-blue--medium;
                    color: $color-blue;
                    @include font-size(24);
                    padding: 0 10px;
                    align-self: stretch;
                    display: flex;
                    align-items: center;
                    flex:1;
                }
            }
            @media(max-width: 1024px){
                display: flex;
                a{
                    display: flex;
                    transform: none;
                    position: unset;
                    width: 100%;
                    span{
                        flex:1;
                    }
                    i{
                        flex:unset;
                    }
                }
            }
        }
        @media (min-width: $screen-md-min){
            &:hover{
                .news_content--link{
                    display: block;
                    a{
                        display: flex;
                    }
                }
            }
        }
    }
    &.news_content--three{
        .news_content{
            .news_content--description{
                @media (min-width: $screen-md-min){
                    padding: 20px 30px;
                }
            }
        }
    }
    &.news_content--four{
        .news_content{
            .news_content--description{
                display: flex;
                justify-content: center;
                p{
                    align-self: center;
                    margin-bottom: 0;
                }
            }
        }
    }
    @media (max-width: $screen-xs-max){
        .container{
            padding: 0;
        }
    }
}