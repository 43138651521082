.module_pagination{
    ul.pagination{
        li{
            a{
                border-radius: 0;
                border: 0;
                @include font-size(14);
                color: $color-grey--dark;
                &:hover{
                    color: $color-blue--medium;
                    background-color: $color-white;
                    border: none;
                    &:after{
                        display: block;
                        content:"";
                        border-bottom: 1px solid;
                    }
                }
                &:focus{
                    background-color: $color-white;
                    border: none;
                    color: $color-blue--medium;
                    font-family: $font-telefonica--bold;
                }
            }
            &:first-of-type,
            &:last-of-type{
                a{
                    color: $color-black;
                }
            }
        }
    }
}