.module_beadcrumbs{
    display: none;
    .breadcrumb{
        color: $color-grey--dark;
        @include font-size(16);
        margin-bottom: 10px;
        padding: 0;
        background-color: transparent;
        li a{
            &:hover{
                color: $color-blue--medium;
            }
        }
        .active{
            color: $color-black;
        }
        &>li+li:before{
            content: "\f105";
            font-family: 'FontAwesome';
            @include font-size(14);
        }
    }
    @media (min-width: $screen-sm-min){
        display: block;
    }
}