.module_download-btn{
    padding: 20px 0;
    .module_download-btn--title{
        @include font-size(18);
    }
    .download-btn{
        border: 1px solid $color-blue;
        display: flex;
        align-content: stretch;
        align-items: stretch;
        margin-bottom: 15px;
        .download-btn--info{
            color: $color-blue--medium;
            @include font-size(18);
            flex: 1;
            flex-basis: 90%;
            padding: 5px 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p{
                margin-bottom: 0;
            }
            .year{
                color: $color-blue;
                font-family: $font-telefonica--bold;
                @include font-size(18);
            }
            @media (min-width: $screen-md-min){
                padding: 5px 20px;
                @include font-size (16);
            }
            @media (min-width: $screen-sm-min){
                padding: 5px 10px;
                @include font-size (16);
            }
        }
        .download-btn--link{
            color: $color-blue--light;
            background-color: $color-blue;
            @include font-size(22);
            padding: 10px 30px;
            flex:1;
            flex-basis: 8%;
            display: flex;
            align-items: center;
            &:hover{
                opacity: .9;
            }
            @media (min-width: $screen-sm-min){
                padding: 10px 20px;
            }
        }
    }
    @media (min-width: $screen-sm-min){
        padding: 20px;
    }
}