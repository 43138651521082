.module_products-nav{
    .nav-selector{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 84px;
        padding: 6px 100px 6px 12px;
        border: 1px solid #003245;
        background-image: linear-gradient(to right, $color-blue, $color-blue);
        background-position: calc(100% - 3.6em) 0;
        background-size: 1.5px 100%;
        background-repeat: no-repeat;
        p{
            margin-bottom: 0;
            & + div{
                @include font-size(30);
                color: $color-blue--medium;
                position: absolute;
                right: 23px;
                top: 50%;
                transform: translate(-30%,-50%);
                padding: 0 0 2px;
                position: absolute;
                pointer-events: none;
            }
        }
        @media (min-width: $screen-sm-min){
            display: none;
        }
    }
    ul{
        display: none;
        flex-direction: column;
        padding: 0;
        border: 1px solid $color-blue;
        border-top: 0;        
        li{
            @include font-size(22);
            border-bottom: .5px solid $color-grey--dark;
            a{
                padding: 20px;
                display: block;
                img{
                    max-width: 32px;
                    display: inline-block;
                    margin-right: 20px;
                }
                p{
                    display: inline-block;
                }
            }
        }
    }
    @media (min-width: $screen-md-min){
        ul{
            li{
                width: auto;
                margin: 20px 5px 0 0;
                flex: 1;
            }
        }
    }
    @media (min-width: $screen-sm-min){
        ul{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-width: 100%;
            justify-content: space-between;
            border: none;
            position: static;
            li{
                width: 24%;
                box-shadow: 0 0 10px 0 rgba(88,88,88,.2);
                text-align: center;
                border-bottom: 0;
                margin-top: 10px;
                @include font-size(18);
                &:last-of-type{
                    margin-left:0;
                }
                &.active{
                    box-shadow: 1px 1px 15px 0 rgba(88,88,88,.4);
                    p{
                        color: $color-black;
                    }
                }
                &.hover:not(.active){
                    img{
                        transform: scale(1.1);
                    }
                }
                a{
                    padding: 20% 0;
                    img{
                        display: block;
                        margin: 0 auto;
                    }
                    p{
                        margin-top: 10px;
                        margin-bottom: 0;
                        color: $color-grey--dark;
                        display: block;
                    }
                }
            }
        }
    }
    @media (max-width: $screen-xs-max){
        ul li.active{
            border-top: 0;
        }
    }
}