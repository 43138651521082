.module_form-contact{
    small{
        display: inline-block;
    }
    .module_form--buttons{
        display: inline-block;
        .module_form--cancel,
        .module_form--save{
            display: inline-block;
        }
    } 
    .mensaje.OK, .mensaje.KO{
        display: none;
    }
    &.success{
        .mensaje.OK{
            display: block;
        }
        .content{
            display: none;
        }
    }
    &.error{
        .mensaje.KO{
            display: block;
        }
        .content{
            display: none;
        }
    }
    .module_form--footer{
        display: flex;
        align-items: flex-end;
        small{
            flex: 1;
        }
        .module_form--buttons{
            align-self: flex-end;
        }
        @media (max-width: $screen-xs-max){
            flex-direction: column;
        }
    }
}