.btn{
    margin-top: 15px;
    padding: 10px 20px;
    border: 1px solid $color-blue;
    border-radius: 0;
    @include font-size(16);
    font-family: $font-telefonica--bold;
    color: $color-blue;
    &:before{
        margin-right: 10px;
        font-family: 'FontAwesome';
        font-weight: bold;
    }
    &:hover{
        opacity: .8;
    }

    &.btn--blue{
        background-color: $color-blue;
        color: $color-white;
    }

    &.btn--blue--medium{
        background-color: $color-blue--medium;
        border: 1px solid $color-blue--medium;
        color: $color-white;
    }

    &.btn--blue--light{
        background-color: $color-blue--light;
        border: 1px solid $color-blue--light;
        color: $color-blue;
    }
}