body{
    /*height: 100vh;*/
    margin: 0;
    .background-img{
        width: 100%;
        top:0;
        bottom: 0;
        position: fixed;
        z-index: -2;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .background-color{
        background-color: rgba(205,205,180,0.85);
        width: 100%;
        top:0;
        bottom: 0;
        position: fixed;
        z-index: -1;
    }
    .public-content{
        min-height: 100%;
        margin-bottom: 54px;
        .public-container{
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            margin-left: auto;
            margin-right: auto;
            .portal-senior{
                background-color: $color-light-brown;
                width: 100%;
                h1{
                    text-align: center;
                    font-size: 36px;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-top: 14px;
                    padding-bottom: 14px;
                }
            }
            .public-menu-content{
                background-color: $color-white;
                width: 100%;
                padding: 42px;
                margin-bottom: 0;
                .col-md-12{
                    padding: 0;
                }
                .public-title{
                    margin: 0;
                    font-size: 60px;
                    text-transform: uppercase;
                }
                .public-subtitle{
                    color: $color-blue--medium;
                    font-size: 40px;
                    margin-bottom: 0;
                }
                .public-p{
                    font-size: 21px;
                    margin-bottom: 0px;
                }
                .public-data{
                    color: $color-grey--dark;
                    font-size: 21x;
                    margin-bottom: 12px;
                }
                .public-input{
                    width: 100%;
                    background-color: transparent;
                    border-width: 0 0 1px;
                    height: auto;
                    padding-right: ($input-height-base * 1.25);
                }
                ::placeholder{
                    color: $color-grey--light;
                }
                .bold{
                    font-weight: bold;
                }
                .underline{
                    text-decoration: underline;
                }
                .public-footer{
                    margin-bottom: 16px;
                    font-size: 21px;
                    cursor: pointer;
                }
                .button-blue, .button-white{
                    width: 100%;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    margin-top: 0;
                    text-align: center;
                    font-size: 24px;
                }
                .public-login-content{
                    padding-top: 54px;
                    padding-bottom: 80px;
                    .public-login{
                        padding-left: 0px;
                        padding-right: 0px;
                        .public-data{
                            margin-top: 0px;
                        }
                        .public-input{
                            margin-bottom: 42px;
                        }
                        .public-footer{
                            margin-bottom: 36px;
                        }
                        .button-blue{
                            margin-bottom:91px;
                        }
                    }
                    .public-register-login{
                        padding-left: 0px;
                        padding-right: 0px;
                        .button-blue{
                            margin-bottom: 0;
                        }
                    }
                    .public-subtitle{
                        margin-top: 0px;
                        margin-bottom: 26px;
                    }
                    .public-p{
                        margin-bottom: 42px;
                    }
                }
                .public-authorization-content{
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    padding-top: 21px;
                    .public-p{
                        margin-bottom: 80px;
                    }
                    .module_form--buttons{
                        margin-top: 0;
                        .module_form--edit{
                            margin-left: auto;
                            margin-right: auto;
                            .ico{
                                font-size: 1.2rem;
                                font-weight: bolder;
                                padding-top: 1px;
                                padding-bottom: 2px;
                            }
                            span{ 
                                font-weight: bold;                      
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-top: 21px;
                                padding-bottom: 21px;
                            }
                        }
                    }
                }
                .public-password-content{
                    padding-top: 26px;
                    .public-p{
                        margin-top: 0;
                        padding-top: 0;
                    }
                    .data-passwords{
                        &.new{
                            padding-top: 42px;
                        }
                        .public-subtitle{
                            margin-top: 0;
                            margin-bottom: 48px;
                        }
                        .public-data{
                            margin-top: 0;
                        }
                        .public-input{
                            margin-bottom: 42px;
                        }
                    }
                    .public-footer{
                        color: $color-grey--medium;
                        font-size: 21px;
                    }
                }
                .public-register-content{
                    padding-top: 48px;
                    .public-subtitle{
                        margin-top: 0px;
                        margin-bottom: 26px;
                    }
                    .public-p{
                        margin-bottom: 42px;
                    }
                    input{
                        display: inline-block;
                        float: left;
                        margin-right: 8px;
                        margin-bottom: 32px;
                    }
                    .public-checkbox{
                        .bold{
                            font-size: 21px;
                        }
                        .public-checkmark{
                            &.checked{
                                background-image: url('../images/icn_checked.png');
                                display: block;
                                z-index: 4;
                                width: 16px;
                                height: 16px;
                                position: absolute;
                                left: -21px;
                                top: 1px;
                            }
                        }
                        .public-check-text{
                            display: inline;
                            position: relative;
                            margin-left: 8px;
                            .public-checkmark{
                                height: 16px;
                                width: 16px;
                                background-color: $color-white;
                                border: 1px solid $color-grey--medium;
                                position: absolute;
                                left: -22px;
                                top: -2px;
                            } 
                            .public-p{
                                padding-top: 0;
                                font-size: 18px;
                                margin-top: 0;
                            }
                        }
                        &.colectivo{
                            position:  relative;
							display: inline;
                            margin-left: 21px;
                            margin-bottom: 32px;
                            .public-check-text{
                                margin-left: 7px;
                                .public-checkmark{
                                    top:8px;
                                    .checkbox1{
                                        display: none;
                                     }
                                }
                                .public-p{
                                    margin-bottom: 0;
                                    display: block;
                                }
                            } 
                        }
                        &.condiciones{
                            position: relative;
                            .public-check-text{
                                margin-left: 21px;
                                .public-checkmark{
                                    top: 9px;
                                    left: -22px;
                                    .checkbox2{
                                        display: none;
                                     }
                                }
                                .public-p{
                                    display: inline;
                                }
                            }
                        }
                        .public-footer{
                            font-size: 18px;
                        }
                    }
                    .date-selector{
                        background-color: $color-grey--lighter;
                        border: 15px solid $color-grey--lighter;
                        border-top-width: 0px;
                        border-bottom-width: 0px;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        width: 100%;
                        padding-top: 21px;
                        padding-left: 15px;
                        padding-right: 15px;
                        margin-bottom: 32px;
                        h3{
                            text-transform: uppercase;
                            padding-left: 15px;
                            font-weight: bolder;
                            font-size: 21px;
                            color: $color-grey-dark-2;
                            margin-top: 0;
                            .purple{
                                color: $color-purple;
                            }
                        }
                        .selected-date{
                            text-transform: uppercase;
                            margin-left: 15px;
                            margin-bottom: 10px;
                        }
                        .selector{
                            backgroung-color: $color-grey--medium;
                            text-align: center;
                            color: $color-grey--dark-medium;
                            font-weight: bold;
                            .btn{
                                font-size: 2.5rem;
                            }
                            .button-grey{
                                color: $color-grey--dark-medium;
                                background-color: $color-grey-light-medium;
                                border: none;
                            }
                            .selected{
                                text-transform: uppercase;
                                padding-bottom: 26px;
                                padding-top: 81px;
                                font-size: 18px;
                                display: block;
                            }
                        }
                        .month-selector, .year-selector{
                            margin-bottom: 15px;
                        }
                        .month-selector, .left{
                            padding-left: 0;
                            padding-right: 7px;
                            margin-bottom: 15px;
                        }
                        .year-selector, .right{
                            padding-left: 7px;
                            padding-right: 0;
                        }
                        .month-selector{
                            .selected-month{
                                display: none;
                            }
                        }
                        .year-selector{
                            .selected-year{
                                display: none;
                            }
                        }
                        .btn{
                            margin-top: 0px;
                            margin-bottom: 0px;
                            margin-right: 0px;
                            width: 100%;
                            padding-bottom: 0px;
                            padding-top: 0px;
                        }
                        .buttons{
                            display: none;
                            padding-top: 15px;
                            padding-right: 15px;
                            padding-left: 15px;
                            background-color: $color-grey--light-1; 
                            text-align: center;
                            .button-grey-white, .button-dark-grey{
                                padding-top: 16px;
                                padding-bottom: 16px;
                            }
                            .button-grey-white{
                                color: $color-grey--dark-medium;
                                background-color: $color-white;
                                border: none;
                                margin-right: 7px;
                                width: 48%;
                            }
                            .button-dark-grey{
                                color: $color-white;
                                background-color: $color-grey--dark-medium;
                                border: none;
                                margin-left: 4px;
                                width: 100%;
                            }
                        }
                    }
                    .button-blue, .button-white{
                        margin-top: 8px;
                    }
                }
            }
        }
    }
    footer{
        height: auto;
        width: 100%;
        /*margin-top: 0;*/
    }
    @media (min-width: 768px){
        .public-content{
            .public-container{
                .portal-senior{
                    display: none;
                }
                .public-menu-content{
                    background-color: $color-white;
                    width: 70%;
                    padding: 42px 118px 34px 105px;
                    margin: 36px auto 36px auto;
                    .public-title{
                        margin-top: 0;
                        font-size: 48px;
                    }
                    .public-subtitle{
                        font-size: 24px;
                    }
                    .public-p, .public-data{
                        font-size: 14px;
                    }
                    .public-footer{
                        font-size: 12px;
                    }
                    .public-login-content{
                        padding-bottom: 10px;
                        .public-login{
                            padding-top: 20px;
                            padding-right: 50px;
                            .public-p{
                                margin-bottom: 26px;
                            }
                            .public-input{
                                margin-bottom: 26px;
                            }
                            .public-footer{
                                margin-bottom: 16px;
                            }
                            .button-blue{
                                margin-bottom: 12px;
                            }
                        }
                        .public-register-login{
                            padding-left: 50px;
                            .public-p{
                                margin-bottom: 42px;
                            }
                        }
                        .public-subtitle{
                            margin-top: 0px;
                            margin-bottom: 26px;
                        }
                    }
                    .public-authorization-content{
                        text-align: center; 
                        padding-top: 58px;
                        margin-top: 0;
                        padding-bottom: 121px;
                        .public-p{
                            margin-top: 0;
                            margin-bottom: 124px;
                        }
                        .module_form--buttons{
                            .module_form--edit{
                                span{
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                }
                            }
                        }
                    }
                    .public-password-content{
                        padding-top: 32px;
                        .public-p{
                            margin-top: 0;
                            margin-bottom: 26px;
                        }
                        .data-passwords {
                            .public-input{
                                margin-bottom: 28px;
                            }
                            .public-subtitle{
                                margin-top: 0;
                                margin-bottom: 26px;
                            }
                            .public-data{
                                margin-top: 0;
                            }
                        }
                        .button-blue{
                            margin-bottom: 12px;
                        }
                        .public-footer{
                            font-size: 14px;
                        }
                    }
                    .public-register-content{
                        padding-top: 36px;
                        .public-subtitle{
                            margin-top: 0px;
                            margin-bottom: 21px;
                        }
                        .public-p{
                            margin-bottom: 16px;
                        }
                        .public-data{
                            margin-bottom: 12px;
                        }
                        .public-footer{
                            margin-bottom: 32px;
                        }
                        .public-checkbox{
                            .bold{
                                font-size: 14px;
                            }
                            .public-check-text{
                                margin-left: 0px;
                                display:  inline-block;
                                margin-top: 0px;
                                padding-top: 0px;
                                .public-p{
                                    font-size: 12px;
                                }
                            }
                            &.colectivo{
                                .public-check-text{
                                    .public-checkmark{
                                        top: 3px;
                                    }
                                }
                            }
                            &.condiciones{
                                .public-check-text{
                                    .public-checkmark{
                                        top: 4px;
                                        left: -21px;
                                    }
                                }
                            }
                        }
                        .date-selector{
                            width: 400px;
							margin-top:20px;
                            h3{
                                font-size: 14px;
                            }
                        }
                    }
                    .button-blue{
                        width: auto;
                        display: inline-block;
                        padding: 5px 32px 5px;
                        font-size: 16px;
                    }
                    .button-white{
                        width: auto;
                        display: inline-block;     
                        float: left;
                        margin-right: 8px;
                        padding: 5px 25px 5px;
                        font-size: 16px;
                    }
                    footer{
                        height:54px;
                    }
                }
            }
        }
    }
}