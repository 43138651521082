ol {
	counter-reset:li; /* Initiate a counter */
	margin-left:0; /* Remove the default left margin */
	padding-left:0; /* Remove the default left padding */
}
ol > li {
	position:relative; /* Create a positioning context */
	margin:0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
	padding:4px 8px; /* Add some spacing around the content */
	list-style:none; /* Disable the normal item numbering */
}
ol > li:before {
	content:counter(li, decimal-leading-zero); /* Use the counter as content */
	counter-increment:li; /* Increment the counter by 1 */
	/* Position and style the number */
	position:absolute;
	top:-2px;
	left:-2em;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;
	width:2em;
	/* Some space between the number and the content in browsers that support
	   generated content but not positioning it (Camino 2 is one example) */
	margin-right:8px;
	padding:4px;
	text-align:center;
}
li ol,
li ul {margin-top:6px;}
ol ol li:last-child {margin-bottom:0;}