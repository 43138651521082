body{
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    
    font-size: 24px;
    font-family: $font-telefonica;
    @media (min-width: $screen-sm-min){
        font-size: 16px;
    }
}

main{
    margin-top: 72px;

    section{
        margin-bottom: 3px;
        /*&:last-of-type{
            margin-bottom: 0;
        }*/
    }
}

small{
    color: $color-grey--dark;
    @include font-size(14);
}

a, input, textarea, select{
    &:focus,
    &:active{
        outline: none;
        box-shadow: none;
    }
}