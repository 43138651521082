.module_list{
    padding: 20px 0;
    @media (min-width: $screen-sm-min){
        padding: 20px;  
    }
    .module_list--title{
        @include font-size(18);
    }
    .module_list--list{
        @include font-size(18);
        .module_list--list-item{
            padding-left: 0;
            &:before{
                color:$color-blue--medium;
                font-family:$font-telefonica;
                @include font-size(20);
                padding-left: 8px;
            }
            img{
                margin: 10px auto;
            }
        }
        @media (min-width: $screen-sm-min){
            @include font-size(14);
        }
    }
}