.module_products{
    .module_products--title{
        @include font-size(24);
        color: $color-blue--medium;
    }
    .module_products_content{
        margin: 5px 5px 30px 0;
        position: relative;
        box-shadow: 0 0 10px 0 rgba(88,88,88,.2);
        .module_products--img{
            img{
                min-width: 100%;
            }
        }
        .module_products_content--description{
            background-color: $color-white;
            padding: 20px;
            text-align: center;
            color: $color-black;
            @include font-size(24);
            @media (min-width: $screen-sm-min){
                padding: 30px 20px;
            }
        }
        .module_products_content--link{
            a{
                display: flex;
                align-items: stretch;
                display: none;
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translate(-50%);
                span{
                    background-color: $color-blue;
                    color: $color-white;
                    @include font-size(16);
                    padding: 15px 20px;
                    align-self: center;
                    @media (min-width: $screen-sm-min){
                        padding: 6px 10px;
                    }
                }
                i{
                    background-color: $color-blue--light;
                    color: $color-blue;
                    @include font-size(24);
                    padding: 0 25px;
                    align-self: stretch;
                    display: flex;
                    align-items: center;
                    flex:1;
                    @media (min-width: $screen-sm-min){
                        padding: 0 10px;
                    }
                }
            }
            @media(max-width: 1024px){
                display: flex;
                a{
                    display: flex;
                    transform: none;
                    position: unset;
                    width: 100%;
                    span{
                        flex:1;
                    }
                    i{
                        flex:unset;
                    }
                }
            }
        }
        @media (min-width: $screen-md-min){
            &:hover{
                .module_products_content--link{
                    display: block;
                    a{
                        display: flex;
                    }
                }
            }
        }
    }
}