.module_form{
    margin-top: 30px;

    .terms{
        margin-top: 40px;
        @include font-size(14);
        color: $color-blue;
        span{
            font-family: $font-telefonica--bold;
        }
    }

    .form-group{
        position: relative;

        .error{
            color: $color-red;
            position: absolute;
            top: 31px;
            line-height: 1;
            margin: 0;
            @media (min-width: $screen-sm-min){
                top: 20px;
            }
        }

        .iban{
            text-transform: uppercase;
        }
    }
    label{
        color: $color-grey--dark;
        @include font-size(14);
    }
    label + .ico{
        display: none;
    }
    select::-ms-expand {
        display: none;
    }
    input, select{
        appearance: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding: 0 25px 0 0;
        border-bottom: 2px solid $color-blue--medium;
        margin-top: 20px;
        @include font-size(16);
        &:focus,
        &:active{
            outline: none;
            box-shadow: none;
            border-color: $color-blue--medium;
        }
        &:disabled{
            background-color: $color-white;
            cursor: auto;
            border:none;
            border-radius: 0;
            box-shadow: none;
            border-bottom: 1px solid $color-grey;
        }
        @media (min-width: $screen-sm-min){
            margin-top: 0;
        }
    }
    input:read-only{
        background-color: $color-white;
        cursor: auto;
        border:none;
        border-radius: 0;
        box-shadow: none;
        border-bottom: 1px solid $color-grey;
        &:focus,
        &:active{
            border-bottom: 1px solid $color-grey;
        }
    }
    textarea{
        resize: vertical;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid $color-blue--medium;
        box-shadow: none;
        min-height: 112px;
        padding: 0;
        @include font-size(16);
        &:focus,
        &:active{
            box-shadow: none;
            outline: none;
            border-color: 2px solid $color-blue--medium;
        }
    }
    &.editing{
        label + .ico{
            display: block;
            position: absolute;
            right: 0;
            bottom: 10px;
            padding: 0;
            @include font-size(20);
            color: $color-blue--medium;
        }
    }
    @media (min-width: $screen-sm-min){
        margin-top: 0;
    }

    .actual, .new{
        p{
            margin-bottom: 0;
            @include font-size(18);
            &.module_form--title{
                font-family: $font-telefonica--bold;
            }
            @media (min-width: $screen-sm-min){
                @include font-size(16);
            }
        }
        label + .ico{
            display: block;
            position: absolute;
            right: 0;
            bottom: 10px;
            padding: 0;
            font-size: 20px;
            font-size: 1rem;
            color: #2593B5;
        }
        input{
            margin-bottom: 20px;
        }
    }
}

.module_form--buttons{
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin-top: 20px;
    .module_form--edit{
        display: flex;
        color: $color-blue--medium;
        @include font-size(16);
        .ico{
            background-color: rgba($color: $color-blue--light, $alpha: .3);
            justify-self: stretch;
            display: flex;
            align-items: center;
            padding: 5px 10px;
        }
        span{
            background-color: rgba($color: $color-blue--light, $alpha: .1);
            justify-self: center;
            padding: 5px 10px;
        }
    }
    .module_form--save,
    .module_form--cancel{
        display: none;
        border: 1px solid $color-blue;
        color: $color-blue;
        padding: 5px 30px;
        margin-left: 10px;
        background-color: $color-white;
        cursor: pointer;
        &.visible{
            display: block;
        }
    }
    .module_form--save{
        background-color: $color-blue;
        color: $color-white;
    }

    @-moz-document url-prefix() { 
        .module_form--save:-moz-read-only,
        .module_form--cancel:-moz-read-only{
            display: none;
            border: 1px solid $color-blue;
            color: $color-blue;
            padding: 5px 30px;
            margin-left: 10px;
            background-color: $color-white;
            cursor: pointer;
            &.visible{
                display: inline-block;
            }
        }
        .module_form--save:-moz-read-only{
            background-color: $color-blue;
            color: $color-white;
        }
      }
}
